import {createRouter, createWebHistory} from "vue-router";

const routes = [
    {
        path: "/login",
        name: "login",
        component: () => import(/* webpackChunkName: "authFunctions" */"../views/Auth/Login.vue"),
    },
    {
        path: "/cars",
        name: "cars",
        component: () => import(/* webpackChunkName: "masterData" */"../views/MasterData/Car/Cars.vue"),
    },
    {
        path: "/new-car",
        name: "newCar",
        component: () => import(/* webpackChunkName: "masterData" */"../views/MasterData/Car/NewCar.vue"),
    },
    {
        path: "/cars/:carId",
        name: "car",
        component: () => import(/* webpackChunkName: "masterData" */"../views/MasterData/Car/Car.vue"),
        props: true
    },
    {
        path: "/car-types",
        name: "carTypes",
        component: () => import(/* webpackChunkName: "masterData" */"../views/MasterData/CarType/CarTypes.vue"),
    },
    {
        path: "/new-car-type",
        name: "newCarType",
        component: () => import(/* webpackChunkName: "masterData" */"../views/MasterData/CarType/NewCarType.vue"),
    },
    {
        path: "/car-types/:carTypeId",
        name: "carType",
        component: () => import(/* webpackChunkName: "masterData" */"../views/MasterData/CarType/CarType.vue"),
        props: true
    },
    {
        path: "/staffs",
        name: "staffs",
        component: () => import(/* webpackChunkName: "masterData" */"../views/MasterData/Staff/Staffs.vue"),
    },
    {
        path: "/new-staff",
        name: "newStaff",
        component: () => import(/* webpackChunkName: "masterData" */"../views/MasterData/Staff/NewStaff.vue"),
    },
    {
        path: "/staffs/:staffId",
        name: "staff",
        component: () => import(/* webpackChunkName: "masterData" */"../views/MasterData/Staff/Staff.vue"),
        props: true
    },
    {
        path: "/workflows",
        name: "workflows",
        component: () => import(/* webpackChunkName: "masterData" */"../views/MasterData/Workflow/Workflows.vue"),
    },
    {
        path: "/new-workflow",
        name: "newWorkflow",
        component: () => import(/* webpackChunkName: "masterData" */"../views/MasterData/Workflow/NewWorkflow.vue"),
    },
    {
        path: "/workflows/:workflowId",
        name: "workflow",
        component: () => import(/* webpackChunkName: "masterData" */"../views/MasterData/Workflow/Workflow.vue"),
        props: true
    },
    {
        path: "/customers",
        name: "customers",
        component: () => import(/* webpackChunkName: "masterData" */"../views/MasterData/Customer/Customers.vue"),
    },
    {
        path: "/new-customer",
        name: "newCustomer",
        component: () => import(/* webpackChunkName: "masterData" */"../views/MasterData/Customer/NewCustomer.vue"),
    },
    {
        path: "/customers/:customerId",
        name: "customer",
        component: () => import(/* webpackChunkName: "masterData" */"../views/MasterData/Customer/Customer.vue"),
        props: true
    },
    {
        path: "/subcontractors",
        name: "subContractors",
        component: () => import(/* webpackChunkName: "masterData" */"../views/MasterData/SubContractor/SubContractors.vue"),
    },
    {
        path: "/new-subcontractor",
        name: "newSubContractors",
        component: () => import(/* webpackChunkName: "masterData" */"../views/MasterData/SubContractor/NewSubContractor.vue"),
    },
    {
        path: "/subcontractors/:subContractorId",
        name: "subContractor",
        component: () => import(/* webpackChunkName: "masterData" */"../views/MasterData/SubContractor/SubContractor.vue"),
        props: true
    },
    {
        path: "/colors",
        name: "colors",
        component: () => import(/* webpackChunkName: "masterData" */"../views/MasterData/Color/Colors.vue"),
    },
    {
        path: "/new-color",
        name: "newColor",
        component: () => import(/* webpackChunkName: "masterData" */"../views/MasterData/Color/NewColor.vue"),
    },
    {
        path: "/colors/:colorId",
        name: "color",
        component: () => import(/* webpackChunkName: "masterData" */"../views/MasterData/Color/Color.vue"),
        props: true
    },
    {
        path: "/workflows-preparedness",
        name: "workflowsPreparedness",
        component: () => import(/* webpackChunkName: "masterData" */"../views/MasterData/WorkflowPreparedness/WorkflowsPreparedness.vue"),
    },
    {
        path: "/new-workflows-preparedness",
        name: "newWorkflowPreparedness",
        component: () => import(/* webpackChunkName: "masterData" */"../views/MasterData/WorkflowPreparedness/NewWorkflowPreparedness.vue"),
    },
    {
        path: "/workflows-preparedness/:workflowPreparednessId",
        name: "workflowPreparedness",
        component: () => import(/* webpackChunkName: "masterData" */"../views/MasterData/WorkflowPreparedness/WorkflowPreparedness.vue"),
        props: true
    },
    {
        path: "/new-preparedness-schemas",
        name: "newPreparednessSchema",
        component: () => import(/* webpackChunkName: "masterData" */"../views/MasterData/PreparednessSchema/NewPreparednessSchema.vue"),
    },
    {
        path: "/preparedness-schemas",
        name: "preparednessSchemas",
        component: () => import(/* webpackChunkName: "masterData" */"../views/MasterData/PreparednessSchema/PreparednessSchemas.vue"),
    },
    {
        path: "/preparedness-schemas/:schemaId",
        name: "preparednessSchema",
        component: () => import(/* webpackChunkName: "masterData" */"../views/MasterData/PreparednessSchema/PreparednessSchema.vue"),
        props: true
    },
    {
        path: "/preparedness-warnings/",
        name: "preparednessWarnings",
        component: () => import(/* webpackChunkName: "masterData" */"../views/MasterData/PreparednessWarning/PreparednessWarnings.vue"),
    },
    {
        path: "/preparedness-warnings/:warningId",
        name: "preparednessWarning",
        component: () => import(/* webpackChunkName: "masterData" */"../views/MasterData/PreparednessWarning/PreparednessWarning.vue"),
        props: true
    },
    {
        path: "/car-moves",
        name: "carMoves",
        component: () => import(/* webpackChunkName: "process" */"../views/Process/CarMove/CarMoves.vue"),
    },
    {
        path: "/timesheets",
        name: "timesheets",
        component: () => import(/* webpackChunkName: "process" */"../views/Process/TimeSheet/TimeSheet.vue"),
    },
    {
        path: "/preparedness-cars",
        name: "preparednessCars",
        component: () => import(/* webpackChunkName: "process" */"../views/Process/PreparednessCar/PreparednessCars.vue"),
    },
    {
        path: "/preparedness-cars/:carId",
        name: "preparednessCar",
        component: () => import(/* webpackChunkName: "process" */"../views/Process/PreparednessCar/PreparednessCar.vue"),
        props: true
    },
    {
        path: "/finances",
        name: "finances",
        component: () => import(/* webpackChunkName: "process" */"../views/Process/Finance/Finances.vue"),
    },
    {
        path: "/finances/:carId",
        name: "finance",
        component: () => import(/* webpackChunkName: "process" */"../views/Process/Finance/Finance.vue"),
        props: true
    },
    {
        path: "/warnings",
        name: "warnings",
        component: () => import(/* webpackChunkName: "process" */"../views/Process/Warning/Warning.vue")
    },
    {
        path: "/car-info",
        name: "carsInfo",
        component: () => import(/* webpackChunkName: "queries" */"../views/Queries/CarInfo/CarsInfo.vue"),
    },
    {
        path: "/car-info/:carId",
        name: "carInfo",
        component: () => import(/* webpackChunkName: "queries" */"../views/Queries/CarInfo/CarInfo.vue"),
        props: true
    },
    {
        path: "/over-hours",
        name: "overHours",
        component: () => import(/* webpackChunkName: "queries" */"../views/Queries/OverHour/OverHours.vue"),
    },
    {
        path: "/car-workflow-hour",
        name: "carWorkflowHour",
        component: () => import(/* webpackChunkName: "queries" */"../views/Queries/CarWorkflowHour/CarWorkflowHour.vue"),
    },
    {
        path: "/full-query",
        name: "fullQuery",
        component: () => import(/* webpackChunkName: "queries" */"../views/Queries/FullQuery/FullQuery.vue"),
    },
    {
        path: "/active-status",
        name: "activeStatus",
        component: () => import(/* webpackChunkName: "queries" */"../views/Queries/ActiveStatus/ActiveStatus.vue"),
    },
    {
        path: "/setups",
        name: "setups",
        component: () => import(/* webpackChunkName: "settings" */"../views/Settings/Setups.vue"),
    },
    {
        path: "/uploads",
        name: "uploads",
        component: () => import(/* webpackChunkName: "settings" */"../views/Settings/Uploads.vue"),
    },
    {
        path: "/no-permission",
        name: "noPermission",
        component: () => import(/* webpackChunkName: "slavePages" */"../views/Slave/NoPermission.vue"),
    },
    {
        path: "/:pathMatch(.*)*",
        name: "page404",
        component: () => import(/* webpackChunkName: "slavePages" */"../views/Slave/PathNotFound.vue"),
    },
];

const router = createRouter({
    scrollBehavior(to, from, savedPosition) {
        let dropdown = document.querySelector("#navbarNav")
        if(dropdown){
            dropdown.classList.remove("show")
        }
        if (savedPosition) {
            return savedPosition;
        } else {
            return {top: 0};
        }
    },
    history: createWebHistory('/'),
    linkActiveClass: "active-link",
    routes,
});

export default router;
