<template>
  <nav class="shadow-sm navbar-custom navbar-expand-md fixed-top" :class="{'menu-show' : menuVisible}">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">Motor <span>Classic</span> Sys</router-link>
      <div class="btn-toggle" @click="menuToggle">
        <i class="bi bi-list"></i>
        <i class="bi bi-x"></i>
      </div>
      <div class="user-block" v-if="userData!==null">
        <span class="small text-muted me-2">EUR {{exchangeRate.eur}} Ft</span>
        <router-link class="me-3" :to="{name: 'warnings'}"><i class="bi bi-bell"></i></router-link>
        {{firstName}} <i class="bi bi-person-circle"></i>
      </div>
    </div>
  </nav>
</template>

<script>

export default {
    name: "Header",
    props: ["initData", "contents"],
    computed: {
      userData(){
        return this.initData.user
      },
      firstName(){
        return this.initData.user.firstName
      },
      exchangeRate(){
        let userData = this.initData.user
        if(userData.exchangeRate!==null){
          return userData.exchangeRate
        }
        return null
      },
      menuVisible(){
        return this.initData.display.leftSideMenu
      }
    },
  methods:{
    menuToggle(){
      let menuVisible = this.initData.display.leftSideMenu
      let newState = true
      if(menuVisible===true){
        newState = false
      }
      this.initData.display.leftSideMenu = newState
    }
  }
}
</script>

<style scoped>
.navbar-custom{
  width: 100%;
  height: 70px;
  z-index: 1002;
  background: linear-gradient(90deg, rgba(49,58,70,0) 0px, rgba(255,255,255,1) 0px);
  transition: all 0.3s ease-in-out;
}
.navbar-custom.menu-show{
  background: linear-gradient(90deg, rgba(49,58,70,0) 280px, rgba(255,255,255,1) 280px);
}

.navbar-brand{
  float: left;
  color: #6c757d!important;
  line-height: 70px;
  width: 160px;
  padding-left: 20px;
  transition: all 0.3s ease-in-out;
}
.navbar-custom.menu-show .navbar-brand{
  color: #fff!important;
  width: 270px;
}
.navbar-brand span{
  color: var(--motor-classic);
}
.btn-toggle{
  width: 70px;
  float: left;
  font-size: 2rem;
  line-height: 70px;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease-in-out;
}
.btn-toggle .bi.bi-x{
  color: var(--motor-classic);
  display: none;
}
.btn-toggle:hover{
  color: var(--motor-classic);
}
.user-block{
  float: right;
  padding-top: 1.6rem;
  line-height: 1rem;
  text-align: right;
}

@media (max-width: 992px) {
  .left-menu-opened .btn-toggle .bi.bi-list{
    display: none;
  }
  .left-menu-opened .btn-toggle .bi.bi-x{
    display: block;
  }
}
@media (max-width: 576px) {
  .navbar-brand{
    padding-left: 0;
    width: 120px;
  }
  .btn-toggle{
    width: 45px;
  }
  .navbar-custom.menu-show .navbar-brand {
    width: 200px;
  }
  .user-block{

  }
  .left-menu-opened .user-block{
    display: none;
  }

}
</style>