<template>
  <div class="left-side-menu" :class="{'show' : menuVisible}">
    <ul class="side-nav">
      <template v-if="menus!==null && allowedMenu!==undefined" v-for="(menu,index) in menus" :key="index">
        <li class="side-nav-title side-nav-item" v-if="parentMenuIsAllowed(menu.id)">
          <span>{{menu.name}}</span>
        </li>
        <li class="side-nav-item" v-for="(subMenu,subIndex) in menu.subMenu.filter(subMenu => subMenu.id !== '10' && subMenu.id!=='17' && subMenu.id!=='18')">
          <router-link class="side-nav-link" :to="{name: subMenu.url}" v-if="['2','3','4','5','6','7','9','11','12','14','15','16','20','21','22','23','24','25','26','27','28'].includes(subMenu.id) && allowedMenu.includes(subMenu.id)">
            <span><i :class="subMenu.icon"></i> {{subMenu.name}}</span>
          </router-link>
          <a class="side-nav-link" :href="'/?content='+subMenu.url" v-else-if="allowedMenu.includes(subMenu.id)">
            <span><i :class="subMenu.icon"></i> {{subMenu.name}}</span>
          </a>
        </li>
      </template>
      <li class="side-nav-item mt-5">
        <a class="side-nav-link text-danger" @click="logOut">
          <span><i class="bi bi-power"></i> Kijelentkezés</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import {Response} from "@/classes/response";

export default {
  name: "LeftSideMenu",
  props: ['initData'],
  computed: {
    menuVisible(){
      return this.initData.display.leftSideMenu
    },
    allowedMenu(){
      return this.initData.user.allowedMenu
    },
    menus(){
      return this.initData.menus
    }
  },
  methods:{
    parentMenuIsAllowed(parentId){
      //GET PARENT MENUS
      let parentMenus = this.menus.filter(menu => menu.id === parentId)
      //GET SUBMENU
      let subMenus = parentMenus[0].subMenu
      if(this.allowedMenu!==undefined){
        //GET ALLOWED SUBMENUS
        let allowedSubMenus = subMenus.filter(subMenu => this.allowedMenu.includes(subMenu.id))
        if(allowedSubMenus.length > 0){
          return true
        }
      }
      return false
    },
    logOut(){
      let postData = {}
      this.initData.loader.visible = true
      this.$axios.post('/api/v1/user/logOut',postData)
          .then((response) => {
            let responseData =  new Response().handle(response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.status===true) {
              //REDIRECT
              //this.$router.push({name: 'login'})
              window.location.href = '/'
              //SESSION HASH STORAGE
              localStorage.removeItem('motorClassic_sessionHash')

              this.initData.user = null
              this.initData.menus = null
            }
          })
          .catch(error => {
            console.log(error)
            let response =  new Response().errorHandle(error.response)
            this.initData.alerts.push({msg: response.msg, type: response.msgType})
          })
          .then(() => {
            this.initData.loader.visible = false
          });

    }
  }
}
</script>

<style scoped>
.left-side-menu{
  width: 280px;
  min-width: 280px;
  z-index: 1000;
  background: #313a46;
  bottom: 0;
  position: fixed;
  top: 0;
  padding-top: 70px;
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  transform: translate(-100%, 0);
  transition: all 0.3s ease-in-out;
}
.left-side-menu.show{
  transform: translate(0, 0);
}
.side-nav {
  overflow-y: scroll;
  height: 100%;
  padding-left: 0;
  list-style-type: none;
  padding-bottom: 3rem;
  scroll-behavior: smooth;
}
.side-nav::-webkit-scrollbar-track{
  border-radius: 8px;
  background-color: #313a46;
}
.side-nav::-webkit-scrollbar{
  width: 8px;
  background-color: #313a46;
}
.side-nav::-webkit-scrollbar-thumb{
  border-radius: 10px;
  background-color: var(--motor-classic);
}
.side-nav .side-nav-link {
  color: #8391a2;
  display: block;
  padding: 10px 30px;
  font-size: .9375rem;
  position: relative;
  transition: color .3s ease-in-out,background-color .3s ease-in-out,border-color .3s ease-in-out;
  list-style: none;
  cursor: pointer;
}
.side-nav .side-nav-link:hover{
  color: #bccee4;
}
.side-nav .side-nav-link i{
  animation-duration:.7s;
}
.side-nav .side-nav-link:hover i{
  animation-name:swing;
}
.side-nav .side-nav-link.active-link{
  color: var(--motor-classic);
}
.side-nav .side-nav-title {
  padding: 12px 30px;
  letter-spacing: .05em;
  pointer-events: none;
  cursor: default;
  font-size: .6875rem;
  text-transform: uppercase;
  color: #8391a2;
  font-weight: 700;
}
.side-nav .side-nav-link i {
  display: inline-block;
  line-height: 1.0625rem;
  margin: 0 10px 0 0;
  font-size: 1.1rem;
  /*vertical-align: middle;*/
  width: 20px;
}
.side-nav .side-nav-link span {
  vertical-align: middle;
}
@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
</style>