export class Response {
    constructor() {
    }

    handle(response) {
        let responseData = response.data;

        this.status = responseData.status;
        this.redirect = responseData.redirect;
        this.msg = responseData.msg;
        this.msgKey = responseData.msgKey;
        this.data = responseData.data;

        if (this.msgKey !== null && this.msgKey.substr(0, 7) === "session") {
            console.log(this.msgKey);
            console.log(this.msgKey.substr(0, 7));
            setTimeout(() => {
                localStorage.removeItem('motorClassic_sessionHash')
                window.location.assign("/");
            }, 3000);
        }

        if (this.status === true && this.redirect != null) {
            this.redirectTo(this.redirect);
            return false;
        } else if (this.status === true && this.msg != null) {
            responseData.msgType = "success";
            return responseData;
        } else if (this.status === false && this.msg != null && this.redirect == null) {
            responseData.msgType = "error";
            return responseData;
        } else if (this.status === false && this.msg != null && this.redirect != null) {
            this.redirectTo(this.redirect);
            responseData.msgType = "error";
            return responseData;
        }

        return responseData;
    }

    errorHandle(response) {
        let error = {
            msgType: null,
            msg: null,
        };
        let status = response.status;

        switch (status) {
            case 400 :
                error.msgType = "error";
                error.msg = "A szerver nem elérhető, vagy Internet nincs, vagy a központi szerver nem elérhető.";
                return error;
            case 500 :
                error.msgType = "error";
                error.msg = "Server kódbázis hiba, vedd fel a kapcsolatot a fejlesztővel.";
                return error;
            default:
                error.msgType = "error";
                error.msg = "Valami félrement, hibakód: " + status + "<br>Vedd fel a kapcsolatot a fejlesztővel.";
                return error;
        }
    }

    redirectTo(redirect) {
        window.location.assign(redirect);
    }
}