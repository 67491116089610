<template>
    <div class="alert-deck">
        <Alerts :alerts="alert" v-for="alert in initData.alerts" />
    </div>
    <InitLoader :initData="initData"></InitLoader>
    <Loader :initData="initData"></Loader>
    <Header v-if="initData.initLoader===false && !notDisplayHeader.includes(currentRouteName)" :initData="initData"></Header>
    <LeftSideMenu v-if="initData.initLoader===false && !notDisplayHeader.includes(currentRouteName)" :initData = "initData"></LeftSideMenu>
    <router-view v-if="initData.initLoader===false" :initData="initData"/>
    <Footer v-if="initData.initLoader===false" :initData="initData"></Footer>
    <Download v-if="initData.initLoader===false" :initData = "initData"></Download>
</template>

<script>
import {reactive} from "vue";
import Alerts from "@/components/Alerts.vue";
import {Response} from "@/classes/response";
import InitLoader from "@/components/InitLoader.vue";
import Loader from "@/components/Loader.vue";
import Header from "@/components/Header.vue";
import LeftSideMenu from "@/components/LeftSideMenu.vue";
import Footer from "@/components/Footer.vue";
import Download from "@/components/Download.vue";

export default {
    name: "App",
    components: {
        Footer,
        Header,
        LeftSideMenu,
        Loader,
        InitLoader,
        Alerts,
        Download
    },
    data() {
      let leftSideMenuDisplay = true
      if(window.innerWidth <=992 ){
        leftSideMenuDisplay = false
      }

      const initData = reactive({
          'user': null,
          'initLoader': true,
          'loader': {
            'visible': false,
            'upload' : null,
            'msg' : null
          },
          'alerts': [],
          'modals': {
            'download': {
              'imageSrc': null,
              'pdfSrc': null
            }
          },
          'offCanvas' : {
            'addFinanceItems' : {},
            'addSetupItems' : {},
          },
          'display' : {
            'leftSideMenu' : leftSideMenuDisplay
          },
          'menus' : [],
          'carTypes' : [],
          'customers' : [],
          'colors' : [],
          'cars' : [],
          'staffs' : [],
          'workflows' : [],
      });

      return {
          initData,
          notDisplayHeader: ["undefined", "login"],
          notDisplayLeftMenu: ['undefined','login'],
          notAuthContent: ["undefined", "login"],
      };
    },
    computed: {
      currentRouteName() {
          let routeName = this.$route.name
          //IF NOT LOGINED BUT WANT SEE AUT REQUIRED PAGE
          if (this.initData.user !== null && !this.notAuthContent.includes(routeName) && this.initData.user.auth === false) {
              this.$router.push({name: "login"})
          }
          if (routeName === undefined) {
              return "undefined"
          } else {
              return routeName
          }
      },
      menuVisible(){
        this.leftMenuContent()
        return this.initData.display.leftSideMenu
      }
    },
    watch:{
      menuVisible(){
        this.leftMenuContent()
      }
    },
    created() {
        this.userInit();
    },
    methods: {
        userInit() {
            this.$axios.get('/api/v1/user')
                .then((response) => {
                    let responseData = new Response().handle(response);
                    this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType});

                    if (responseData.status === true) {
                        console.log(responseData);
                        this.initData.user = responseData.data.user
                        this.initData.menus = responseData.data.menus
                        this.initData.carTypes = responseData.data.carTypes
                        this.initData.carStatuses = responseData.data.carStatuses
                        this.initData.customers = responseData.data.customers
                        this.initData.colors = responseData.data.colors
                        this.initData.cars = responseData.data.cars
                        this.initData.staffs = responseData.data.staffs
                        this.initData.workflows = responseData.data.workflows
                    }
                })
                .catch(error => {
                    console.log(error);
                    let response = new Response().errorHandle(error.response);
                    this.initData.alerts.push({msg: response.msg, type: response.msgType});
                })
                .then(() => {
                    this.initData.initLoader = false;
                });
        },
        leftMenuContent(){
          let body = document.querySelector('body')
          if(this.menuVisible || (this.menuVisible===undefined && window.innerWidth >992)){
            body.classList.add('left-menu-opened')
          }else{
            body.classList.remove('left-menu-opened')
          }
        }
    }
}
</script>