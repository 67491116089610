<template>
  <div class="modal fade" id="downloadModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <img v-if="imageSrc!==null" :src="imageSrc" alt=""/>
          <iframe v-if="pdfSrc" :src="pdfSrc"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {Response} from "@/classes/response";
import {Modal} from "bootstrap";

export default {
  name: 'Download',
  props: ['initData'],
  computed:{
    imageSrc(){
      return this.initData.modals.download.imageSrc
    },
    pdfSrc(){
      return this.initData.modals.download.pdfSrc
    }
  },
  methods:{
    document(e,carId,documentId,documentType){
      e.initData.loader.visible = true

      let postData = {
        'carId' : carId,
        'documentId' : documentId,
        'documentType' : documentType,
      }

      e.$axios.post('/api/v1/process/document/download',postData)
          .then((response) => {
            let responseData =  new Response().handle(response)
            e.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.status===true) {
              let file = responseData.data.file
              let fileName = responseData.data.fileName
              let fileType = responseData.data.fileType
              let fileSize = responseData.data.fileSize
              let fileExtension = fileName.split('.').pop();

              const url = 'data:'+fileType+';base64,'+file;
              //IF IMAGE
              if((fileExtension==='jpg' || fileExtension==='jpeg' || fileExtension==='png') && fileSize<2000000){
                e.initData.modals.download.imageSrc = url;
                e.initData.modals.download.pdfSrc = null;
                let modalInstance = new Modal(document.getElementById('downloadModal'))
                modalInstance.show()
              }else if(fileExtension==='pdf' && fileSize<2000000){
                e.initData.modals.download.imageSrc = null;
                e.initData.modals.download.pdfSrc = url;
                let modalInstance = new Modal(document.getElementById('downloadModal'))
                modalInstance.show()
              }else{
                e.initData.modals.download.imageSrc = null;
                e.initData.modals.download.pdfSrc = null;
                //ELSE DOWNLOAD
                const downloadLink = document.createElement('a');
                document.body.appendChild(downloadLink);
                downloadLink.href = url;
                downloadLink.download = fileName;
                downloadLink.click();
                document.body.removeChild(downloadLink);
              }
            }
          })
          .catch(error => {
            console.log(error)
            let response =  new Response().errorHandle(error.response)
            e.initData.alerts.push({msg: response.msg, type: response.msgType})
            e.initData.loader.visible = false
          })
          .then(() => {
            e.initData.loader.visible = false
          });
    }
  }
}
</script>
<style scoped>
.modal-body img{
  max-width: 100%;
}
.modal iframe{
  width: 100%!important;
  min-height: 1400px;
}
</style>